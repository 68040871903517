var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bg-a-list"},[_c('a-spin',{attrs:{"spinning":_vm.spinning}},[_c('div',{staticClass:"search-box"},[_c('a-input',{staticStyle:{"width":"200px"},attrs:{"placeholder":"请选择项目"},on:{"click":_vm.selectProjectModal},model:{value:(_vm.searchFormInline.ProjectName),callback:function ($$v) {_vm.$set(_vm.searchFormInline, "ProjectName", $$v)},expression:"searchFormInline.ProjectName"}}),(
          _vm.searchFormInline.ProjectId &&
          _vm.isGranted('Pages.UnnaturalProjectAgreementV2Permissions.Add')
        )?_c('a-button',{staticStyle:{"margin-left":"0","float":"right","z-index":"10"},attrs:{"type":"primary","icon":"plus-circle"},on:{"click":function($event){_vm.showSelectContractType = true}}},[_vm._v(" 添加合同 ")]):_vm._e()],1),(_vm.contractList.length > 0)?_c('a-list',{staticStyle:{"padding":"10px"},attrs:{"item-layout":"vertical","data-source":_vm.data,"size":"small"}},[_c('a-list-item',{attrs:{"slot":"renderItem"},slot:"renderItem"},[_c('a-list-item-meta',[_c('a',{staticStyle:{"font-size":"14px","font-weight":"400"},attrs:{"slot":"title"},slot:"title"},[_vm._v("合同/协议")])]),_vm._l((_vm.contractList),function(item,index){return _c('a-row',{key:index,class:index > 0 ? 'botline contract-box' : 'botline',attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":8}},[_c('div',{staticClass:"company-title"},[_vm._v(" 协议名称："+_vm._s(item.agreementTemplateName)+" ")]),_c('div',{staticClass:"contract-handler"},[(
                  _vm.isGranted(
                    'Pages.UnnaturalProjectAgreementV2Permissions.Update'
                  )
                )?_c('a',{attrs:{"type":"link"},on:{"click":function($event){return _vm.goEditmpleteV2(
                    item.agreementTemplateId,
                    _vm.searchFormInline.ProjectId,
                    item.agreementGroupType
                  )}}},[_vm._v(" 修改模版 ")]):_vm._e(),(
                  _vm.isGranted(
                    'Pages.UnnaturalProjectAgreementV2Permissions.Update'
                  )
                )?_c('a',{staticStyle:{"margin-left":"10px"},attrs:{"type":"link"},on:{"click":function($event){return _vm.gotoWatch(
                    item.agreementTemplateId,
                    _vm.searchFormInline.ProjectId,
                    item.agreementGroupType
                  )}}},[_vm._v(" 修改签章 ")]):_vm._e(),(
                  _vm.isGranted(
                    'Pages.UnnaturalProjectAgreementV2Permissions.SendSign'
                  )
                )?_c('a',{staticStyle:{"margin-left":"10px"},on:{"click":function($event){return _vm._projectAgreementModal(Object.assign({}, item,
                    {projectId: _vm.searchFormInline.ProjectId}))}}},[_vm._v(" 发起签署 ")]):_vm._e()])]),_c('a-col',{attrs:{"span":8}},[_c('div',{staticClass:"contract-des dec-center"},[_vm._v(" 签约公司："+_vm._s(item.orgName)+" ")])]),_c('a-col',{attrs:{"span":8}},[_c('div',{staticClass:"contract-des dec-right"},[_vm._v(" 项目名称："+_vm._s(item.projectName)+" ")])]),_c('a-col',{staticClass:"sealbox-step",attrs:{"span":24}},[_c('div',[_c('a-steps',{staticClass:"customer-steps",attrs:{"size":"small","current":99},scopedSlots:_vm._u([{key:"progressDot",fn:function(ref){
                  var prefixCls = ref.prefixCls;
return _c('a-popover',{},[_c('span',{class:(prefixCls + "-icon-dot")})])}}],null,true)},_vm._l((item.signingMethods),function(sign){return _c('a-step',{key:sign.sort,attrs:{"title":_vm.getSignerTypeName(sign.signerType),"description":_vm.getSignMethod(sign)}})}),1)],1)])],1)})],2)],1):_vm._e(),(_vm.proveList.length > 0)?_c('a-list',{staticStyle:{"padding":"10px"},attrs:{"item-layout":"vertical","data-source":_vm.data,"size":"small"}},[_c('a-list-item',{attrs:{"slot":"renderItem"},slot:"renderItem"},[_c('a-list-item-meta',[_c('a',{staticStyle:{"font-size":"14px","font-weight":"400"},attrs:{"slot":"title"},slot:"title"},[_vm._v("证明文件类")])]),_vm._l((_vm.proveList),function(item,index){return _c('a-row',{key:index,class:index > 0 ? 'botline contract-box' : 'botline',attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":8}},[_c('div',{staticClass:"company-title"},[_vm._v(" 协议名称："+_vm._s(item.agreementTemplateName)+" ")]),_c('div',{staticClass:"contract-handler"},[(
                  _vm.isGranted(
                    'Pages.UnnaturalProjectAgreementV2Permissions.Update'
                  )
                )?_c('a',{attrs:{"type":"link"},on:{"click":function($event){return _vm.goEditmpleteV2(
                    item.agreementTemplateId,
                    _vm.searchFormInline.ProjectId,
                    item.agreementGroupType
                  )}}},[_vm._v(" 修改模版 ")]):_vm._e(),(
                  _vm.isGranted(
                    'Pages.UnnaturalProjectAgreementV2Permissions.Update'
                  )
                )?_c('a',{staticStyle:{"margin-left":"10px"},attrs:{"type":"link"},on:{"click":function($event){return _vm.gotoWatch(
                    item.agreementTemplateId,
                    _vm.searchFormInline.ProjectId,
                    item.agreementGroupType
                  )}}},[_vm._v(" 修改签章 ")]):_vm._e(),(
                  _vm.isGranted(
                    'Pages.UnnaturalProjectAgreementV2Permissions.SendSign'
                  )
                )?_c('a',{staticStyle:{"margin-left":"10px"},on:{"click":function($event){return _vm._projectAgreementModal(Object.assign({}, item,
                    {projectId: _vm.searchFormInline.ProjectId}))}}},[_vm._v(" 发起签署 ")]):_vm._e()])]),_c('a-col',{attrs:{"span":8}},[_c('div',{staticClass:"contract-des dec-center"},[_vm._v(" 签约公司："+_vm._s(item.orgName)+" ")])]),_c('a-col',{attrs:{"span":8}},[_c('div',{staticClass:"contract-des dec-right"},[_vm._v(" 项目名称："+_vm._s(item.projectName)+" ")])]),_c('a-col',{staticClass:"sealbox-step",attrs:{"span":24}},[_c('div',[_c('a-steps',{staticClass:"customer-steps",attrs:{"size":"small","current":99},scopedSlots:_vm._u([{key:"progressDot",fn:function(ref){
                  var prefixCls = ref.prefixCls;
return _c('a-popover',{},[_c('span',{class:(prefixCls + "-icon-dot")})])}}],null,true)},_vm._l((item.signingMethods),function(sign){return _c('a-step',{key:sign.sort,attrs:{"title":_vm.getSignerTypeName(sign.signerType),"description":_vm.getSignMethod(sign)}})}),1)],1)])],1)})],2)],1):_vm._e(),(_vm.contractList.length == 0 && _vm.proveList.length == 0)?_c('a-empty',{staticStyle:{"margin-top":"200px"},attrs:{"description":_vm.searchFormInline.ProjectId
          ? '该项目暂无协议，请点击添加协议新增协议'
          : '请选择项目'}}):_vm._e()],1),_c('a-modal',{attrs:{"title":"请选择你要添加的协议类型","visible":_vm.showSelectContractType,"okText":"添加","cancelText":"取消"},on:{"ok":_vm.gotoAddContract,"cancel":function($event){_vm.showSelectContractType = false}}},[_c('p',[_vm._v("请选择协议类型")]),_c('a-select',{staticStyle:{"width":"100%"},model:{value:(_vm.selectContractType),callback:function ($$v) {_vm.selectContractType=$$v},expression:"selectContractType"}},[_c('a-select-option',{attrs:{"value":1}},[_vm._v(" 证明文件类 ")]),_c('a-select-option',{attrs:{"value":2}},[_vm._v(" 合同协议类 ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }