<template>
  <div class="bg-a-list">
    <a-spin :spinning="spinning">
      <div class="search-box">
        <a-input
          v-model="searchFormInline.ProjectName"
          placeholder="请选择项目"
          @click="selectProjectModal"
          style="width: 200px"
        >
        </a-input>
        <a-button
          style="margin-left: 0; float: right; z-index: 10"
          type="primary"
          icon="plus-circle"
          v-if="
            searchFormInline.ProjectId &&
            isGranted('Pages.UnnaturalProjectAgreementV2Permissions.Add')
          "
          @click="showSelectContractType = true"
        >
          添加合同
        </a-button>
      </div>

      <a-list
        item-layout="vertical"
        :data-source="data"
        size="small"
        style="padding: 10px"
        v-if="contractList.length > 0"
      >
        <a-list-item slot="renderItem">
          <a-list-item-meta>
            <a slot="title" style="font-size: 14px; font-weight: 400"
              >合同/协议</a
            >
          </a-list-item-meta>
          <a-row
            v-for="(item, index) in contractList"
            :class="index > 0 ? 'botline contract-box' : 'botline'"
            :gutter="24"
            :key="index"
          >
            <a-col :span="8">
              <div class="company-title">
                协议名称：{{ item.agreementTemplateName }}
              </div>

              <div class="contract-handler">
                <a
                  type="link"
                  v-if="
                    isGranted(
                      'Pages.UnnaturalProjectAgreementV2Permissions.Update'
                    )
                  "
                  @click="
                    goEditmpleteV2(
                      item.agreementTemplateId,
                      searchFormInline.ProjectId,
                      item.agreementGroupType
                    )
                  "
                >
                  修改模版
                </a>
                <a
                  type="link"
                  style="margin-left: 10px"
                  v-if="
                    isGranted(
                      'Pages.UnnaturalProjectAgreementV2Permissions.Update'
                    )
                  "
                  @click="
                    gotoWatch(
                      item.agreementTemplateId,
                      searchFormInline.ProjectId,
                      item.agreementGroupType
                    )
                  "
                >
                  修改签章
                </a>
                <a
                  style="margin-left: 10px"
                  v-if="
                    isGranted(
                      'Pages.UnnaturalProjectAgreementV2Permissions.SendSign'
                    )
                  "
                  @click="
                    _projectAgreementModal({
                      ...item,
                      projectId: searchFormInline.ProjectId,
                    })
                  "
                >
                  发起签署
                </a>
              </div>
            </a-col>

            <a-col :span="8">
              <div class="contract-des dec-center">
                签约公司：{{ item.orgName }}
              </div>
            </a-col>
            <a-col :span="8">
              <div class="contract-des dec-right">
                项目名称：{{ item.projectName }}
              </div>
            </a-col>
            <a-col class="sealbox-step" :span="24">
              <div>
                <a-steps class="customer-steps" size="small" :current="99">
                  <a-popover slot="progressDot" slot-scope="{ prefixCls }">
                    <span :class="`${prefixCls}-icon-dot`" />
                  </a-popover>

                  <a-step
                    v-for="sign in item.signingMethods"
                    :title="getSignerTypeName(sign.signerType)"
                    :description="getSignMethod(sign)"
                    :key="sign.sort"
                  />
                </a-steps>
              </div>
            </a-col>
          </a-row>
        </a-list-item>
      </a-list>
      <a-list
        item-layout="vertical"
        :data-source="data"
        size="small"
        style="padding: 10px"
        v-if="proveList.length > 0"
      >
        <a-list-item slot="renderItem">
          <a-list-item-meta>
            <a slot="title" style="font-size: 14px; font-weight: 400"
              >证明文件类</a
            >
          </a-list-item-meta>
          <a-row
            v-for="(item, index) in proveList"
            :class="index > 0 ? 'botline contract-box' : 'botline'"
            :gutter="24"
            :key="index"
          >
            <a-col :span="8">
              <div class="company-title">
                协议名称：{{ item.agreementTemplateName }}
              </div>
              <div class="contract-handler">
                <a
                  type="link"
                  v-if="
                    isGranted(
                      'Pages.UnnaturalProjectAgreementV2Permissions.Update'
                    )
                  "
                  @click="
                    goEditmpleteV2(
                      item.agreementTemplateId,
                      searchFormInline.ProjectId,
                      item.agreementGroupType
                    )
                  "
                >
                  修改模版
                </a>
                <a
                  type="link"
                  style="margin-left: 10px"
                  v-if="
                    isGranted(
                      'Pages.UnnaturalProjectAgreementV2Permissions.Update'
                    )
                  "
                  @click="
                    gotoWatch(
                      item.agreementTemplateId,
                      searchFormInline.ProjectId,
                      item.agreementGroupType
                    )
                  "
                >
                  修改签章
                </a>
                <a
                  style="margin-left: 10px"
                  v-if="
                    isGranted(
                      'Pages.UnnaturalProjectAgreementV2Permissions.SendSign'
                    )
                  "
                  @click="
                    _projectAgreementModal({
                      ...item,
                      projectId: searchFormInline.ProjectId,
                    })
                  "
                >
                  发起签署
                </a>
              </div>
            </a-col>

            <a-col :span="8">
              <div class="contract-des dec-center">
                签约公司：{{ item.orgName }}
              </div>
            </a-col>
            <a-col :span="8">
              <div class="contract-des dec-right">
                项目名称：{{ item.projectName }}
              </div>
            </a-col>
            <a-col class="sealbox-step" :span="24">
              <div>
                <a-steps class="customer-steps" size="small" :current="99">
                  <a-popover slot="progressDot" slot-scope="{ prefixCls }">
                    <span :class="`${prefixCls}-icon-dot`" />
                  </a-popover>

                  <a-step
                    v-for="sign in item.signingMethods"
                    :title="getSignerTypeName(sign.signerType)"
                    :description="getSignMethod(sign)"
                    :key="sign.sort"
                  />
                </a-steps>
              </div>
            </a-col>
          </a-row>
        </a-list-item>
      </a-list>

      <a-empty
        :description="
          searchFormInline.ProjectId
            ? '该项目暂无协议，请点击添加协议新增协议'
            : '请选择项目'
        "
        style="margin-top: 200px"
        v-if="contractList.length == 0 && proveList.length == 0"
      />
    </a-spin>
    <a-modal
      title="请选择你要添加的协议类型"
      :visible="showSelectContractType"
      okText="添加"
      cancelText="取消"
      @ok="gotoAddContract"
      @cancel="showSelectContractType = false"
    >
      <p>请选择协议类型</p>
      <a-select style="width: 100%" v-model="selectContractType">
        <a-select-option :value="1"> 证明文件类 </a-select-option>
        <a-select-option :value="2"> 合同协议类 </a-select-option>
      </a-select>
    </a-modal>
  </div>
</template>

<script>
import { AppComponentBase } from "@/shared/component-base";
import { ModalHelper } from "@/shared/helpers";
import EleTable from "@/components/ele-table";
import moment from "moment";
import { CommonServiceProxy } from "@/shared/common-service";
import { AppConsts } from "@/abpPro/AppConsts";
import TempleteList from "../agreementTypeManage/project-agreement/templete-list-v2";
import SelectPageList from "@/components/linggong/select-page-list";
import { abpService, appSessionService } from "@/shared/abp";
let vm = this;

export default {
  mixins: [AppComponentBase],
  name: "agreementsignmanage",
  components: { EleTable },

  data() {
    return {
      spinning: false,
      data: [
        {
          title: "合同/协议（2）",
        },
      ],
      panes: [
        { title: "天堂的项目", key: "1" },
        { title: "华大基因", key: "2" },
      ],
      activeKey: "1",
      newTabIndex: 0,
      showSelectContractType: false,
      selectContractType: 2,
      searchFormInline: {
        ProjectId: null,
      },
      contractList: [],
      proveList: [],
      userId :undefined
    };
  },
  created() {
    // console.log(this);
    // this.initChild();
    if (appSessionService.userId >0)
    {
      console.log("===============lalal===============");
      console.log(appSessionService.userId)
      this.key = "historySelectProject"+appSessionService.userId;
    }
    this.commonService = new CommonServiceProxy(this.$apiUrl, this.$api);
  },
  mounted() {
    //this.getProjectAgreement();
    let historySelectProject = localStorage.getItem(this.key);
    console.log(historySelectProject);
    if (historySelectProject) {
      this.searchFormInline = JSON.parse(historySelectProject);
      this.getProjectAgreement();
    }
  },
  methods: {
    getSignerTypeName(type) {
      if (type == 1) {
        return "签约公司";
      }
      if (type == 2) {
        return "客户";
      }
      if (type == 3) {
        return "个人";
      }
      if (type == 4) {
        return "代理人";
      }

      return "未知";
    },
    getSignMethod(item) {
      if (item.signMethod == null) {
        if (item.isAutoSign) {
          return "自动盖章";
        } else {
          return "手动签章（验证）";
        }
      } else {
        if (item.signMethod == 0) {
          return "手动签章（验证）";
        }
        if (item.signMethod == 1) {
          return "自动盖章";
        }
        if (item.signMethod == 2) {
          return "手动盖章（不验证）";
        }
      }

      return "未知";
    },
    callback(key) {
      console.log(key);
    },
    gotoAddContract() {
      this.goAddTempleteV2("create", this.searchFormInline.ProjectId);
    },
    goAddTempleteV2(id, projectId) {
      this.$router.push({
        path: `/app/fs/addProjectContract/${id}?type=${this.selectContractType}&projectId=${projectId}&projectName=${this.searchFormInline.ProjectName}`,
      });
    },
    goEditmpleteV2(id, projectId, selectContractType) {
      this.$router.push({
        path: `/app/fs/addProjectContract/${id}?type=${selectContractType}&projectId=${projectId}&projectName=${this.searchFormInline.ProjectName}`,
      });
    },
    goSignMethod(id) {
      this.$router.push({
        path: `/app/fs/unSignMethod/d5da7338-95fb-4217-98e8-08d8eddc785b/0`,
      });
    },
    gotoWatch(agreementTemplateId, projectId, type) {
      this.$router.push({
        path: `/app/fs/addSignMethodv2/${agreementTemplateId}/0?type=${type}&projectId=${projectId}&projectName=${this.searchFormInline.ProjectName}`,
      });
    },
    onEdit(targetKey, action) {
      this[action](targetKey);
    },

    getProjectAgreement() {
      this.spinning = true;
      this.$api
        .request({
          url:
            this.$apiUrl +
            "/api/services/app/UnnaturalPersonProjectAgreement/GetPagedByProjectId",
          method: "GET",
          params: {
            projectId: this.searchFormInline.ProjectId,
          },
        })
        .then((res) => {
          console.log(res);
          this.contractList = [];
          this.proveList = [];
          if (res.status == 200) {
            let clist = res.data.filter((item) => {
              return (
                item.agreementGroupType == 0 || item.agreementGroupType == 2
              );
            });
            console.log(clist);
            if (clist.length > 0) {
              clist.forEach((item) => {
                this.contractList = this.contractList.concat(item.groupList);
              });
            }
            let plist = res.data.filter((item) => {
              return item.agreementGroupType == 1;
            });
            if (plist.length > 0) {
              plist.forEach((item) => {
                this.proveList = this.proveList.concat(item.groupList);
              });
            }
          }
        })
        .catch((e) => {
          //   abp.message.error(this.l("Error500Desc"));
          console.error(e);
        })
        .finally(() => {
          this.spinning = false;
        });
    },
    _projectAgreementModal(item) {
      ModalHelper.create(
        TempleteList,
        {
          id: item.id,
          agreementTemplateId: item.agreementTemplateId,
          projectId: item.projectId,
        },
        {
          isChange: true,
          width: "1000px",
        }
      ).subscribe((res) => {
        if (res) {
          this.refreshGoFirstPage();
        }
      });
    },
    selectProjectModal() {
      ModalHelper.create(
        SelectPageList,
        {
          //   selectType: "checkbox", // checkbox radio
          title: "选择项目", // 显示标题
          url: "/api/services/app/UnnaturalPersonProject/GetSelectPaged", // 数据接口URL
        },
        {
          isChange: true,
          width: "860px",
          heigth: "650px",
        }
      ).subscribe((res) => {
        const { success, data } = res;
        if (success) {
          this.searchFormInline.ProjectId = data.id;
          this.searchFormInline.ProjectName = data.name;
          localStorage.setItem(
            this.key,
            JSON.stringify({
              ...this.searchFormInline,
            })
          );
          // this.getTasksByProjectId(data.id);
          this.getProjectAgreement();
        }
      });
    },
  },
};
</script>
<style scoped lang="less">
.bg-a-list {
  margin: 0;
  background: #fff;
  min-height: 761px;
  padding: 20px;
  padding-top: 0px;
  min-width: 768px;
}
.search-box {
  border-bottom: solid #f2f2f2 1px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.company-title {
  font-size: 16px;
  font-weight: bolder;
}
.contract-des {
  font-size: 12px;
  //color: #bbb8b8;
  margin-top: 5px;
}
.customer-steps {
  margin-top: 10px;
  margin-left: -10px;
}
.contract-handler {
  margin-top: 5px;
}
.handler-box {
  line-height: 60px;
}
.contract-box {
  margin-top: 40px;
}
.botline {
  border-bottom: solid 1px #f2f2f2;
  padding-bottom: 20px;
}
.sealbox-step {
  border: 1px #f2f2f2 solid;
  padding: 12px;
  margin: 10px;
}
.dec-right {
  text-align: right;
}
.dec-center {
  text-align: center;
}
</style>