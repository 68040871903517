<template>
  <a-spin :spinning="spinning">
    <div>
      <div class="modal-header">
        <div class="modal-title" style="width: 100%; text-align: left">
          <span>发起签署</span>
        </div>
      </div>

      <div style="padding-left: 10px; padding-right: 10px" v-if="false">
        <a-space>
          <a-input v-model="SearchDto.realName" :placeholder="l('人员名称')" />
          <a-button
            type="primary"
            @click="getData"
            v-if="
              isGranted('Pages.UnnaturalProjectPermissions.ProjectAgreement')
            "
          >
            {{ l("Search") }}
          </a-button>
          <a-button @click="refreshGoFirstPage"> {{ l("Reset") }}</a-button>
          <a-button
            type="primary"
            :disabled="selectedRowKeys.length === 0"
            @click="batchPass()"
            v-if="isGranted('Pages.LGModule.Signup.BatchPass')"
          >
            <a-icon type="check-square" />
            {{ l("批量发起签署") }}
          </a-button>
        </a-space>
      </div>
      <!-- <react-table
      :table-data="tableData"
      :total-items="totalItems"
      :showTotal="showTotalFun"
      :spinning="spinning"
      :actions-type="actionsType"
      :columns="columns"
      :scroll="{ x: 0 }"
      :current-page="pageNumber"
      @emitRefreshData="clearFilterAndRefresh"
      @emitSortData="updateSortData"
      @emitSelectChange="updateSelectChange"
      @emitOnPageChange="updatePageChange"
      @emitShowSizeChange="updateShowSizeChange"
    > -->
      <ele-table
        ref="rt"
        :columns="columns"
        :table-data="tableData"
        :total-items="totalItems"
        :is-full="false"
        :actionsType="actionsType"
        :current-page="pageNumber"
        :hide-row-selection="false"
        @emitRefreshData="clearFilterAndRefresh"
        @emitSelectChange="updateSelectChange"
        @emitOnPageChange="updatePageChange"
        @emitShowSizeChange="updateShowSizeChange"
      >
        <a-row :gutter="8">
          <a-col :span="5">
            <a-input
              v-model="SearchDto.realName"
              allowClear
              :placeholder="l('人员名称')"
            />
          </a-col>
          <a-col :span="5">
            <a-input
              v-model="SearchDto.phoneNumber"
              allowClear
              :placeholder="l('电话号码')"
            />
          </a-col>
           <a-col class="gutter-row" :span="5">
          <a-select
            style="width: 100%"
            @change="selectRealNamedChange"
            placeholder="协议情况"
            v-model="SearchDto.notified"
            allowClear
          >
            <a-select-option
               :value="it.value"
              v-for="(it, i) in notifiedStatusList"
              :key="i"
            >
              {{ it.label }}
            </a-select-option>
          </a-select>
        </a-col>
          <a-col :span="4">
            <a-button
              type="primary"
              @click="getData"
              v-if="
                isGranted('Pages.UnnaturalProjectPermissions.ProjectAgreement')
              "
            >
              {{ l("Search") }}
            </a-button>
            <a-button @click="refreshGoFirstPage"> {{ l("Reset") }}</a-button>
            <!-- <a-button
            type="primary"
            :disabled="selectedRowKeys.length === 0"
            @click="batchPass()"
            v-if="isGranted('Pages.LGModule.Signup.BatchPass')"
          >
            <a-icon type="check-square" />
            {{ l("批量发起签署") }}
          </a-button> -->
          </a-col>
        </a-row>
      </ele-table>
    </div>
  </a-spin>
</template>

<script>
import { AppComponentBase, ModalComponentBase } from "@/shared/component-base";
import { ModalHelper } from "@/shared/helpers";
import EleTable from "@/components/ele-table";
import { CommonServiceProxy } from "@/shared/common-service";
import { AppConsts } from "@/abpPro/AppConsts";
import Preview from "../preview";
import SigningConfiguration from "./signing-configuration";

let vm = this;

export default {
  mixins: [AppComponentBase, ModalComponentBase],
  name: "agreementsignmanagev2",
  components: { EleTable, Preview },

  data() {
    return {
      spinning: false,
      SearchDto: {
        SkipCount: 0,
        MaxResultCount: 50,
        realName: "",
        Sorting: "",
        filterText: "",
        ProjectId: "",
        phoneNumber: "",
        notified:undefined
      },
        notifiedStatusList: [
        { label: "已发送", value: "Notfied" },
        { label: "未发送", value: "NOtNotfy" },
      ],
      name: "",
      projectData: [],
      selectedRowKeys: [],
      totalItems: 0,
      pageNumber: 1,
      totalPages: 1,
      pagerange: [1, 1],
      pageSizeOptions: ["10", "20", "30", "40"],
      tableData: [],
      columns: [],
      child: "",
      commonService: null,
      selectProjectList: "",
    };
  },
  created() {
    // console.log(this);
    // this.initChild();
    this.fullData(); // 模态框必须,填充数据到data字段
    this.commonService = new CommonServiceProxy(this.$apiUrl, this.$api);
    this.initColumns();
    // this.getProject();
    this.getData();
    this.initActionsType();
  },
  methods: {
    initColumns() {
      var vm = this;
      //   var child = vm.$createElement("a", {
      //     domProps: {
      //       innerHTML: 66,
      //     },
      //     on: {
      //       click: function () {
      //         vm.signClick();
      //       },
      //     },
      //   });
      this.columns = [
        {
          title: this.l("人员名称"),
          dataIndex: "text",
          sorter: false,
          align: "center",
          // width: 600,
          scopedSlots: { customRender: "text" },
        },
        {
          title: this.l("手机号码"),
          dataIndex: "phoneNumber",
          sorter: false,
          align: "center",
          // width: 600,
          scopedSlots: { customRender: "phoneNumber" },
        },
         {
          title: this.l("协议情况"),
          dataIndex: "aggrementSituation",
          sorter: false,
          align: "center",
          // width: 600,
          scopedSlots: { customRender: "aggrementSituation" },
        },
        {
          title: this.l("Action"),
          dataIndex: "actions",
          align: "center",
          // fixed: "right",
          scopedSlots: { customRender: "actions" },
        },
      ];
    },
    // initChild(text) {
    //   var child = this.$createElement("a", {
    //     domProps: {
    //       innerHTML: text,
    //     },
    //     on: {
    //       click: function () {
    //         this.signClick(text);
    //       },
    //     },
    //   });
    //   this.child = child;
    //   console.log(child);
    //   u;
    // },
    _projectAgreementModal(id) {
      ModalHelper.create(
        TempleteList,
        { id: id },
        {
          isChange: true,
          width: "1200px",
        }
      ).subscribe((res) => {
        if (res) {
          this.refreshGoFirstPage();
        }
      });
    },
    search() {
      this.pageNumber = 1;
      this.SearchDto.SkipCount = 0;
      this.getData();
    },
     selectRealNamedChange(value) {
      this.SearchDto.notified = value;
    },
    getData() {
      this.getPaged();
      // this.tableData = [
      //   {
      //     name: "灵活经营服务协议",
      //     totalCount: 120,
      //     sign: 100,
      //     nosign: 20,
      //   },
      //   {
      //     name: "自由者从业协议",
      //     totalCount: 120,
      //     sign: 100,
      //     nosign: 20,
      //   },
      // ];
    },
    async getPaged() {
      this.spinning = true;
      this.$nextTick(() => {
        this.$refs.rt.clearData();
      });
      try {
        const params = {
          projectAgreementId: this.id,
          filterText: this.SearchDto.filterText,
          phoneNumber: this.SearchDto.phoneNumber,
          realName: this.SearchDto.realName,
          isNotfied:this.SearchDto.notified,
          sorting: this.SearchDto.Sorting,
          maxResultCount: this.SearchDto.MaxResultCount,
          skipCount: this.SearchDto.SkipCount,
        };
        console.log(params);
        // return;
        let res = await this.commonService.post({
          url: "/api/services/app/UnnaturalPersonProjectPersonnel/GetListByProjectAgreementIdPage",
          params,
        });
        console.log(res);
        this.tableData = res.items.map((item) => {
          return {
            ...item,
          };
        });
        this.totalItems = res.totalCount;
        this.totalPages = Math.ceil(2 / this.SearchDto.MaxResultCount);
        this.pagerange = [
          (this.pageNumber - 1) * this.SearchDto.MaxResultCount + 1,
          this.pageNumber * this.SearchDto.MaxResultCount,
        ];
        this.spinning = false;
      } catch (error) {
        this.spinning = false;
      }
    },
    initActionsType() {
      let _this = this;
      let obj = {
        type: "sign-manage",
        isShow: true,
        fns: {
          dropList: [
            // {
            //   granted: this.isGranted(
            //     "Pages.LGModule.ProjectPersonel.NotifyList"
            //   ),
            //   name: this.l("发起签署"),
            //   icon: "ant-design",
            //   fn: (data) => {
            //     _this._projectAgreementModal(data);
            //   },
            // },
            {
              granted: true,
              name: this.l("确认合同内容"),
              icon: "profile",
              fn: (data) => {
                _this.lookSignPerson(data);
              },
            },
          ],
        },
      };
      this.actionsType = obj;
    },
    batchPass() {
      const selectCount = this.selectedRowKeys.length;
      if (selectCount <= 0) {
        abp.message.warn(this.l("PleaseSelectAXPlaceHolder", "通过"));
        return;
      }
      let personnelIds = [];
      let projectAgreementId = "";
      for (let i = 0; i < this.selectedRows.length; i++) {
        let item = this.selectedRows[i];
        personnelIds.push(item.value);
        if (projectAgreementId == "") {
          projectAgreementId = this.id;
        }
      }
      this.pass(personnelIds, projectAgreementId);
    },
    async pass(personnelIds, projectAgreementId) {
      this.spinning = true;
      try {
        let res = await this.commonService.post({
          url: "/api/services/app/UnnaturalPersonProjectPersonnelAgreement/BatchInsert",
          params: {
            projectAgreementId: projectAgreementId,
            personnelIds: personnelIds,
          },
        });
        this.$notification["success"]({
          message: "操作成功",
        });
        this.getData();
        this.spinning = false;
      } catch (error) {
        this.spinning = false;
      }
    },
    // 排序更新data
    updateSortData(newV) {
      let { columnKey, order } = newV;
      // this.request.sorting = order ? `${columnKey} ${order}` : "";
      this.getData();
    },
    // 分页
    updatePageChange(newV) {
      let { page, pageSize } = newV;
      console.log(newV);
      this.onChange(page, pageSize);
    },
    updateShowSizeChange(newV) {
      let { current, size } = newV;
      this.showSizeChange(current, size);
    },
    // table选择事件
    updateSelectChange(newV) {
      let { selectedRowKeys, selectedRows } = newV;
      this.onSelectChange(selectedRowKeys, selectedRows);
    },
    onChange(page, pageSize) {
      console.log(page, pageSize);
      this.pageNumber = page;
      this.SearchDto.MaxResultCount = pageSize;
      this.SearchDto.SkipCount = (page - 1) * pageSize;
      this.getData();
    },
    showSizeChange(current, size) {
      this.pageNumber = 1;
      this.SearchDto.MaxResultCount = size;
      this.getData();
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
    },
    lookSign(item) {
      const { pdfTemplateUrl } = item;
      if (pdfTemplateUrl) {
        window.open(`${AppConsts.uploadBaseUrl}${pdfTemplateUrl}`);
      } else {
        this.$message.info("暂无协议");
      }
    },
    // 清除条件并刷新
    clearFilterAndRefresh() {
      this.refreshGoFirstPage();
    },
    refreshGoFirstPage() {
      this.SearchDto.filterText = "";
      this.SearchDto.realName = "";
      this.SearchDto.phoneNumber = "";
      this.SearchDto.notified=undefined;
      this.pageNumber = 1;
      this.SearchDto.SkipCount = 0;
      this.getData();
    },
    // 分页事件
    showTotalFun() {
      return this.l(
        "GridFooterDisplayText",
        this.pageNumber,
        this.totalPages,
        this.totalItems,
        this.pagerange[0],
        this.pagerange[1]
      );
    },
    sendToSign(item) {
      window.open(
        `/#/app/fs/makecontract/${item.projectAgreementId}/${item.personalId}?projectId=${this.projectId}`
      );
    },
    async lookSignPerson(item, signstatus) {
      // 检查是否可发起
      var flag = await this.commonService.get({
        url: "/api/services/app/UnnaturalPersonProjectPersonnelAgreement/CheckIfConfigurationIsRequired",
        params: {
          projectAgreementId: this.id,
          agreementTemplateId: this.agreementTemplateId,
          personalId: item.value,
        },
      });
      // 如果数据已经准备好
      if (flag) {
        this.sendToSign({
          projectAgreementId: this.id,
          agreementTemplateId: this.agreementTemplateId,
          personalId: item.value,
        });
      } else {
        // 未配置好SigningConfiguratio
        ModalHelper.create(
          SigningConfiguration,
          {
            projectAgreementId: this.id,
            agreementTemplateId: this.agreementTemplateId,
            personalId: item.value,
            personalName: item.text,
            projectId: this.projectId,
          },
          {
            isChange: true,
            width: "1000px",
          }
        ).subscribe((flag) => {
          if (flag) {
            // this.refreshGoFirstPage();
            this.sendToSign({
              projectAgreementId: this.id,
              agreementTemplateId: this.agreementTemplateId,
              personalId: item.value,
            });
          }
        });
      }
    },
  },
};
</script>

<style scoped lang="less">
</style>